import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, catchError, firstValueFrom, Observable, pipe, throwError } from 'rxjs';
import { Business, BusinessWithRole, Membership } from './business.types';
import { BrowserService } from '../browser.service';


@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  private readonly _API_URL = environment.apiUrl;
  private readonly _STORAGE_KEY = 'focused_business';

  private _focusedBusinessSubject = new BehaviorSubject<Business | null>(null);

  get focusedBusiness$(): Observable<Business | null> {
    return this._focusedBusinessSubject.asObservable();
  }

  setFocusedBusiness(business: Business): void {
    this._focusedBusinessSubject.next(business);
    this._browserService.setLocalStorage(this._STORAGE_KEY, JSON.stringify(business));
  }

  constructor(
    private _httpClient: HttpClient,
    private _browserService: BrowserService // Add BrowserService dependency
  ) {
    const savedBusiness = this._loadInitialBusiness();
    if (savedBusiness) {
      this._focusedBusinessSubject.next(savedBusiness);
    }
  }

  private _loadInitialBusiness(): Business | null {
    try {
      const savedBusiness = this._browserService.getLocalStorage(this._STORAGE_KEY);
      return savedBusiness ? JSON.parse(savedBusiness) : null;
    } catch {
      return null;
    }
  }

  async getUserBusinesses(): Promise<BusinessWithRole[]> {
    const response = await firstValueFrom(
      this._httpClient.get<{ business: Business, role: string }[]>(`${this._API_URL}/api/v1/business`, { withCredentials: true })
        .pipe(
          catchError(error => {
            console.error('Error getting user businesses:', error);
            return throwError(() => error);
          })
        )
    );

    if (response.length > 0) {
      const { business } = response[0];
      this.setFocusedBusiness(business);
    }

    const businesses = response.map(({ business, role }) => ({ ...business, role: role as Membership }));
    return businesses;
  }

}
