@if (authState$ | async; as authState) {
  <!-- This code was generated by Builder.io -->
  <nav class="flex flex-col justify-between w-14 bg-white border border-solid border-zinc-200 min-h-[588px] max-md:hidden">
    <app-menu [menuItems]="menuItems"></app-menu>
    <div class="flex flex-col p-2 w-full">
      <button class="flex gap-2 justify-center items-center p-2.5 w-full rounded-full min-h-[40px]">
        <img src="https://cdn.builder.io/api/v1/image/assets/2657c647a72a40e5bd9f8e334f049b96/21464c266f2c8f83cd0c4dcdc1c51973341abd735cc94ade30e766fdc26f31e9?apiKey=2657c647a72a40e5bd9f8e334f049b96&" alt="Button Icon" class="object-contain self-stretch my-auto w-5 aspect-square">
      </button>
      <!-- Dropdown Button -->
      <!-- <button class="flex gap-2 justify-center items-center p-2.5 w-full rounded-full min-h-[40px]" id="menu-dropdown" variant="outline" [brnMenuTriggerFor]="menu">Open</button> -->
      <button class="flex gap-2 justify-center items-center p-2.5 w-full rounded-full min-h-[40px]" id="menu-popover" variant="outline" [brnMenuTriggerFor]="menu">
        <img src="https://cdn.builder.io/api/v1/image/assets/2657c647a72a40e5bd9f8e334f049b96/36939c3b8d291c4f3355db833810339fe419f6f0267940d8eba4e7f10675050d?apiKey=2657c647a72a40e5bd9f8e334f049b96&" alt="Popover Icon" class="object-contain self-stretch my-auto w-5 aspect-square">
      </button>
      <ng-template #menu>
        <hlm-menu class="w-56">
          <hlm-menu-group>
            <hlm-menu-label>{{ authState.email }}</hlm-menu-label>
            @if (authState.isLoggedIn) {
              <button (click)="logout()" hlmMenuItem>
                <hlm-icon size="sm" name="lucideLogOut"></hlm-icon>
                <span>Logout</span>
                <!-- <hlm-menu-shortcut>⇧⌘Q</hlm-menu-shortcut> -->
              </button>
            } @else {
              <button (click)="login()" hlmMenuItem>
                <hlm-icon size="sm" name="lucideLogIn"></hlm-icon>
                <span>Login</span>
                <hlm-menu-shortcut>⇧⌘L</hlm-menu-shortcut>
              </button>
            }
          </hlm-menu-group>
          <hlm-menu-separator />
          <hlm-menu-group *ngIf="authState.isLoggedIn">
            <hlm-menu-label>Business</hlm-menu-label>
            <button hlmMenuItem [brnMenuTriggerFor]="businessMenu">
              <hlm-icon size="sm" name="lucideBriefcase"></hlm-icon>
              <span>{{ focusedBusiness?.name || 'Select Business' }}</span>
              <hlm-menu-item-sub-indicator />
            </button>
            <button hlmMenuItem (click)="navigateTo('/subscribe')">
              <hlm-icon size="sm" name="lucideDollarSign"></hlm-icon>
              <span>Subscription</span>
            </button>
            <button hlmMenuItem (click)="navigateTo('/settings')">
              <hlm-icon size="sm" name="lucideSettings"></hlm-icon>
              <span>Settings</span>
            </button>
            <button hlmMenuItem (click)="navigateTo('/invites')">
              <hlm-icon size="sm" name="lucideUsers"></hlm-icon>
              <span>2 Invites remaining</span>
            </button>
            @if (authState.role === 'admin') {
              <button hlmMenuItem (click)="navigateTo('/admin/dashboard')">
                <hlm-icon size="sm" name="lucideShield"></hlm-icon>
                <span>Admin</span>
              </button>
            }
          </hlm-menu-group>
        </hlm-menu>
      </ng-template>
    </div>
  </nav>
  <ng-template #businessMenu>
    <hlm-sub-menu class="w-56 bg-white">
      @for (business of businesses; track business) {
        <button hlmMenuItemRadio [checked]="business._id === selectedBusinessId" (triggered)="selectBusiness(business._id)">
          <hlm-menu-item-radio />
          <hlm-icon size="sm" name="lucideBuilding"></hlm-icon>
          {{ business.name }}
          <span class="text-xs text-grey-800 bg-grey-200 rounded p-1">{{ business.role }}</span>
        </button>
      }
      <hlm-menu-separator />
      <button hlmMenuItem (click)="createNewBusiness()">
        <hlm-icon size="sm" name="lucidePlus"></hlm-icon>
        <span>Create New Business</span>
      </button>
    </hlm-sub-menu>
  </ng-template>
}
