import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MenuComponent } from '../../side-menu/menu/menu.component';
import { AuthService } from '~/app/services/auth.service';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { BusinessService } from '~/app/services/business/business.user.service';
import { BrnMenuTriggerDirective } from '@spartan-ng/ui-menu-brain';
import { HlmIconComponent } from '~/lib/ui/ui-icon-helm/src';

import { BaseService } from '~/app/services/base.service';
import { Business, BusinessWithRole } from '~/app/services/business/business.types';
import { InsightFocusService } from '~/app/services/insight-focus.service';
import { HlmMenuComponent, HlmMenuGroupComponent, HlmMenuItemDirective, HlmMenuItemRadioDirective, HlmMenuItemSubIndicatorComponent, HlmMenuLabelComponent, HlmMenuSeparatorComponent, HlmMenuShortcutComponent, HlmSubMenuComponent } from '~/lib/ui/ui-menu-helm/src';
import { HlmMenuItemRadioComponent } from '~/lib/ui/ui-menu-helm/src/lib/hlm-menu-item-radio.component';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [
    CommonModule,
    MenuComponent,
    BrnMenuTriggerDirective,
    HlmMenuComponent,
    HlmSubMenuComponent,
    HlmMenuItemDirective,
    HlmMenuItemSubIndicatorComponent,
    HlmMenuLabelComponent,
    HlmMenuShortcutComponent,
    HlmMenuSeparatorComponent,
    HlmMenuGroupComponent,
    HlmMenuItemRadioComponent,
    HlmMenuItemRadioDirective,
    HlmIconComponent,
  ],
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SideMenuComponent extends BaseService {
  selectedMenuItem?: string;
  @Input() menuItems: any[] = [];
  businesses: BusinessWithRole[] = [];
  selectedBusinessId: string | null = null;
  focusedBusiness: Business | null = null;

  constructor(
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private readonly _businessService: BusinessService,
    private _insightFocusService: InsightFocusService,
  ) {
    super();
    this.authState$ = this._authService.authState$;
    this.authState$.subscribe(async (authState) => {
      if (authState.isLoggedIn) {
        this.businesses = await this._businessService.getUserBusinesses();
      }
    });

    this.subscribeToBusinessChanges();
    this.subscribeToInsightChanges();
  }

  protected readonly authState$;

  private async subscribeToBusinessChanges() {
    this.subscribe(this._businessService.focusedBusiness$, async (business) => {
      this.focusedBusiness = business;
      if (business) {
        this.selectedBusinessId = business._id;
      } else {
        this.selectedBusinessId = null;
      }
    });
  }

  private subscribeToInsightChanges() {
    this.subscribe(this._insightFocusService.focusedInsightModelChange, async (insightType: string | null) => {
      console.log('Insight focus changed:', insightType); // Add logging
      if (insightType) {
        this.setSelectedMenuItem(insightType);
      } else {
        this.setSelectedMenuItem();
      }
    });
  }

  setSelectedMenuItem(menuItem?: string) {
    this.selectedMenuItem = menuItem;
    this.menuItems.forEach(item => item.isActive = item.label === menuItem);
  }

  selectBusiness(businessId: string) {
    this.selectedBusinessId = businessId;
    const selectedBusiness = this.businesses.find(business => business._id === businessId);
    if (selectedBusiness) {
      this._businessService.setFocusedBusiness(selectedBusiness);
    }
  }

  async logout() {
    await this._authService.logout();
  }

  login() {
    this._router.navigate(['/login']);
  }

  navigateTo(route: string) {
    this._router.navigate([route]);
  }

  createNewBusiness() {
    // Logic to create a new business
  }

  async initSelectedBusiness() {
    const businesses = await this._businessService.getUserBusinesses();
    if (businesses.length > 0) {
      this.selectBusiness(businesses[0]._id);
    }
  }
}
